var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { visible: _vm.visible } },
    [
      _c(
        "a-descriptions",
        { attrs: { size: "small", bordered: "", column: 1 } },
        [
          _c("a-descriptions-item", { attrs: { label: "机构名称" } }, [
            _vm._v(_vm._s(_vm.model.departName))
          ]),
          _c("a-descriptions-item", { attrs: { label: "上级部门" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.parentId))])
          ]),
          _c("a-descriptions-item", { attrs: { label: "机构编码" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.orgCode))])
          ]),
          _c("a-descriptions-item", { attrs: { label: "机构类型" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.orgCategory))])
          ]),
          _c("a-descriptions-item", { attrs: { label: "排序" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.departOrder))])
          ]),
          _c("a-descriptions-item", { attrs: { label: "手机号" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.mobile))])
          ]),
          _c("a-descriptions-item", { attrs: { label: "地址" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.address))])
          ]),
          _c("a-descriptions-item", { attrs: { label: "备注" } }, [
            _c("span", [_vm._v(_vm._s(_vm.model.memo))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }