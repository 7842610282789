var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("j-vxe-table", {
    ref: "vTable",
    attrs: {
      toolbar: "",
      "row-number": "",
      "row-selection": "",
      "keep-source": "",
      height: 484,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      "linkage-config": _vm.linkageConfig
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }